import React from "react";
import "../styles/Footer.css";

const Footer = () => {
    return (
        <div id={"Footer"}>

        </div>
    );
}

export default Footer;
